import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { StlcClassOnHoverDirective } from '@stlc/angular/directives/class-on-hover';
import { StlcDateFromNowDirective } from '@stlc/angular/directives/date-from-now';
import { StlcDurationFromDateDirective } from '@stlc/angular/directives/duration-from-date';
import { StlcFocusDirective } from '@stlc/angular/directives/focus';
import { StlcHotColdDirective } from '@stlc/angular/directives/hot-cold';
import { StlcImgDefaultDirective } from '@stlc/angular/directives/img-default';
import { StlcPercentileDirective } from '@stlc/angular/directives/percentile';
import { StlcProtectedSrcDirective } from '@stlc/angular/directives/protected-src';
import { StlcScrollIntoViewDirective } from '@stlc/angular/directives/scroll-into-view';
import { StlcScrollToTopDirective } from '@stlc/angular/directives/scroll-to-top';
import { StlcMaterialModule } from '@stlc/angular/material';
import { StlcAgePipe } from '@stlc/angular/pipes/age';
import { StlcBattingOrderPipe } from '@stlc/angular/pipes/batting-order';
import { StlcDegreeToClockFacePipe } from '@stlc/angular/pipes/degree-to-clock-face';
import { StlcFeetAndInchesPipe } from '@stlc/angular/pipes/feet-and-inches';
import { StlcNewlinesPipe } from '@stlc/angular/pipes/newlines';
import { StlcNumberToDollarsPipe } from '@stlc/angular/pipes/number-to-dollars';
import { StlcNumberToFixedPipe } from '@stlc/angular/pipes/number-to-fixed';
import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { StlcNumberToRangePipe } from '@stlc/angular/pipes/number-to-range';
import { StlcOutsToInningsPipe } from '@stlc/angular/pipes/outs-to-innings';
import { StlcPitchTypePipe } from '@stlc/angular/pipes/pitch-type';
import { StlcPlayerNamePipe } from '@stlc/angular/pipes/player-name';
import { StlcPlayerOrgClassLevelPipe } from '@stlc/angular/pipes/player-org-class-level';
import { StlcRangePipe } from '@stlc/angular/pipes/range';
import { StlcSafePipe } from '@stlc/angular/pipes/safe';
import { StlcStatPipe } from '@stlc/angular/pipes/stat';
import { StlcTimePipe } from '@stlc/angular/pipes/time';

/**
 * @deprecated Do not use with standalone components
 */
@NgModule({
    declarations: [],
    exports: [
        CommonModule,
        RouterModule,
        StlcMaterialModule,
        StlcAgePipe,
        StlcBattingOrderPipe,
        StlcClassOnHoverDirective,
        StlcDateFromNowDirective,
        StlcDegreeToClockFacePipe,
        StlcDurationFromDateDirective,
        StlcFeetAndInchesPipe,
        StlcFocusDirective,
        StlcHotColdDirective,
        StlcImgDefaultDirective,
        StlcNewlinesPipe,
        StlcNumberToDollarsPipe,
        StlcNumberToFixedPipe,
        StlcNumberToOrdinalPipe,
        StlcNumberToRangePipe,
        StlcOutsToInningsPipe,
        StlcPercentileDirective,
        StlcPitchTypePipe,
        StlcPlayerNamePipe,
        StlcPlayerOrgClassLevelPipe,
        StlcProtectedSrcDirective,
        StlcRangePipe,
        StlcSafePipe,
        StlcScrollIntoViewDirective,
        StlcScrollToTopDirective,
        StlcStatPipe,
        StlcTimePipe,
    ],
    imports: [
        CommonModule,
        RouterModule,
        StlcMaterialModule,
        StlcAgePipe,
        StlcBattingOrderPipe,
        StlcClassOnHoverDirective,
        StlcDateFromNowDirective,
        StlcDegreeToClockFacePipe,
        StlcDurationFromDateDirective,
        StlcFeetAndInchesPipe,
        StlcFocusDirective,
        StlcHotColdDirective,
        StlcImgDefaultDirective,
        StlcNewlinesPipe,
        StlcNumberToDollarsPipe,
        StlcNumberToFixedPipe,
        StlcNumberToOrdinalPipe,
        StlcNumberToRangePipe,
        StlcOutsToInningsPipe,
        StlcPercentileDirective,
        StlcPitchTypePipe,
        StlcPlayerNamePipe,
        StlcPlayerOrgClassLevelPipe,
        StlcProtectedSrcDirective,
        StlcRangePipe,
        StlcSafePipe,
        StlcScrollIntoViewDirective,
        StlcScrollToTopDirective,
        StlcStatPipe,
        StlcTimePipe,
    ],
    providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class StlcSharedModule {}
